/* globals $ */
$.easing.easeInOutQuart = function (x, t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
  return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
};

export default function () {
  var $body = $('body');

  /**
   * @param {HTMLElement} t
   */
  function scrollBody(t) {
    var id = $(t).attr('href');
    var $id = $(id);
    if ($id.length === 0) {
      return;
    }

    if ($id[0].scrollIntoView) {
      $id[0].scrollIntoView({ behavior: 'smooth' });
    } else {
      var pos = $id.offset().top;
      $('body, html').animate({ scrollTop: pos }, 1200, 'easeInOutQuart');
    }
  }

  $('#header__logo, #nav-mobile__logo').click(function () {
    $('body, html').animate({ scrollTop: 0 }, 1200, 'easeInOutQuart');
  });

  $('.nav a.nav-btn, .header__explore')
    .off('click.move')
    .on('click.move', function (e) {
      e.preventDefault();
      scrollBody($(this));
    });

  $('.nav-contact, .contactus_btn')
    .off('click.mailFormOpen')
    .on('click.mailFormOpen', function (e) {
      e.preventDefault();
      setTimeout(function () {
        location.hash = '#contact';
        $body.addClass('mail--open');
      }, 25);
    });

  $('.mailform-close')
    .off('click.mailFormClose')
    .on('click.mailFormClose', function (e) {
      location.hash = '';
      e.preventDefault();
      $body.removeClass('mail--open');
    });

  $('.nav__hambtn')
    .off('click.mobileNavOpen')
    .on('click.mobileNavOpen', function (e) {
      e.preventDefault();
      $body.addClass('nav-mobile--open');
    });

  $('.nav-mobile-close')
    .off('click.mobileNavClose')
    .on('click.mobileNavClose', function (e) {
      e.preventDefault();
      $body.removeClass('nav-mobile--open');
    });

  $('.nav-mobile__body a').on('click', function () {
    $('.nav-mobile-close').click();
  });

  $('.mail-btn').on('click.submit', function (e) {
    e.preventDefault();
    $('#adiscope-mailform').submit();
  });

  function handleContact() {
    if (location.hash === '#contact') {
      $('.nav-contact').trigger('click');
    } else {
      $('.mailform-close').trigger('click');
    }
  }

  window.addEventListener('hashchange', handleContact, false);

  $(function () {
    handleContact();
  });
}
