/* globals $, ScrollMagic */
/* cSpell:ignore contactus */
export default {
  init: function() {
    var controller = new ScrollMagic.Controller({});

    // ScrollMagic 디버깅을 하려면 Scene에 .addIndicators()를 추가

    // 현재 헤더 영역이 보이고 있는 지?
    new ScrollMagic.Scene({
      triggerElement: '.header',
      duration: '100%',
      offset: 0
    })
      .setClassToggle('body', 'head-visible')
      .addTo(controller);

    var elVisibleList = [
      '#section__publishers',
      '.summary__first',
      '.summary__second',
      '#section__features',
      '.feature-1',
      '.feature-2',
      '.feature-3',
      '.feature-4',
      '.feature-5',
      '.feature-6',
      '#section__formats',
      '#format-tab__content-video',
      '#format-tab__content-offerwall',
      '.networks__group-1',
      '.networks__group-2',
      '.networks__group-3',
      '#section__contactus'
    ];
    $.each(elVisibleList, function(i, el) {
      new ScrollMagic.Scene({
        triggerElement: el,
        duration: '180%',
        offset: el !== '#section__contactus' ? -300 : -400
      })
        .setClassToggle(el, 'visible')
        .addTo(controller);
    });

    // 헤더 동영상 영역 지나가면 나오는 메뉴
    new ScrollMagic.Scene({
      triggerElement: '.contents',
      offset: 460
    })
      .setClassToggle('.header__nav', 'header__nav--fixed')
      .addTo(controller);

    // 상단 메뉴 버튼
    new ScrollMagic.Scene({
      triggerElement: '#section__publishers',
      duration: '100%',
      offset: 400
    })
      .setClassToggle('.nav-btn-1', 'nav-btn--on')
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '#section__features',
      duration: '538%',
      offset: 0
    })
      .setClassToggle('.nav-btn-2', 'nav-btn--on')
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '#section__networks',
      duration: '200%',
      offset: 0
    })
      .setClassToggle('.nav-btn-3', 'nav-btn--on')
      .addTo(controller);

  }
};
