/* globals $ */
/* cSpell:ignore adiscope */
import motion from './motion';
import bindBtnEvents from './bindBtnEvents';
import validateForm from './validateForm';

var adiscope = {
  bgVideo: document.getElementById('bg-video'),
  motion: motion,
  bindBtnEvents: bindBtnEvents,
  validateForm: validateForm
};

$(function() {
  adiscope.motion.init();
  adiscope.validateForm();
  adiscope.bindBtnEvents();
});

$(window).on('load', function() {
  $('html').addClass('load-done');

  adiscope.bgVideo.load();
  adiscope.bgVideo.addEventListener('canplaythrough', function() {
    var _this = this;
    $('.header__video').addClass('video-playing');
    setTimeout(function() {
      _this.play();
    }, 100);
  });
});
