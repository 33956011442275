/* globals $ */
export default function() {
  var $form = $('#adiscope-mailform');

  $('.mail-btn').on('click.submit', function() {
    $('#adiscope-mailform')
      .off('submit.btn')
      .on('submit.btn');
  });

  function inputClear() {
    $form.find('input[type="text"], textarea').val('');
  }

  $form.validate({
    rules: {
      first_name: 'required',
      last_name: 'required',
      email: {
        required: true,
        email: true
      },
      country: 'required',
      company: 'required',
      identifier: 'required',
      message: {
        required: true,
        minlength: 10
      }
    },
    messages: {
      email: 'Please enter a valid email address',
      message: {
        required: 'The field is required.',
        minlength: 'Please enter at least 10 characters.'
      }
    },
    submitHandler: function(form) {
      var formUrl =
        location.href.indexOf('www-dev') > -1
          ? 'https://partners-dev.adiscope.com/portal/inquire'
          : 'https://partners.adiscope.com/portal/inquire';

      $.ajax({
        url: formUrl,
        type: 'post',
        data: $(form).serialize(),
        contentType: 'application/x-www-form-urlencoded',
        success: function(data) {
          if (data.success === true) {
            alert('Thank you for your inquiry.\nWe will contact you as soon as\npossible.');
            inputClear();
            $('.mailform-close').click();
          } else {
            alert(
              'You can not send mail due to a temporary error. Please try again in a few minutes.'
            );
            console.log('data', data);
          }
        },
        error: function(err) {
          console.log('err', err);
          alert(
            err.error +
              '\n' +
              err.message +
              '\nYou can not send mail due to a temporary error. Please try again in a few minutes.'
          );
        }
      });
    }
  });
}
